import React from "react";
import videoSource from './service-video.mp4'; // Adjust the path accordingly

export const Services = (props) => {
  const sectionStyle = {
    position: 'relative',
    overflow: 'hidden', // Hide overflow to prevent scrolling
    minHeight: '100vh', // Adjust as needed
  };

  const videoStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 1,
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the color and opacity as needed
    zIndex: 2,
  };

  const contentStyle = {
    position: 'relative',
    zIndex: 3,
    color: '#fff', // Set text color for visibility
    paddingTop: '50px', // Adjust spacing from top as needed
  };

  return (
    <div id="services" className="text-center" style={sectionStyle}>
      <video autoPlay loop muted style={videoStyle}>
        <source src={videoSource} type="video/mp4" />
      </video>
      <div style={overlayStyle}></div>
      <div className="container" style={contentStyle}>
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            Exceptional Care and Grooming for Your Beloved Pets
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
