import React, { useEffect } from 'react';

const Preloader = () => {
  useEffect(() => {
    const handleResize = () => {
      const gif = document.getElementById('preloader-gif');
      const text = document.getElementById('preloader-text');
      
      if (window.innerWidth <= 480) {
        gif.style.width = '200px';
        text.style.fontSize = '16px';
      } else {
        gif.style.width = '500px';
        text.style.fontSize = '20px';
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the right size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={preloaderOverlayStyle}>
      <div style={preloaderContainerStyle}>
        <img
          id="preloader-gif"
          src={require('../assets/cat.gif')}
          alt="Loading..."
          style={preloaderGifStyle}
        />
        <div id="preloader-text" style={textStyle}>
          Loading<span role="img" aria-label="Paw Prints">🐾</span>
        </div>
      </div>
    </div>
  );
};

export default Preloader;

// Define inline styles
const preloaderOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.9)', // Semi-transparent background
  zIndex: 9999,
};

const preloaderContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const preloaderGifStyle = {
  width: '500px', // Default width for larger screens
  height: 'auto', // Automatically adjust height to maintain aspect ratio
};

const textStyle = {
  textAlign: 'center',
  marginTop: '20px', // Adjust the distance between the GIF and text
  color: '#fff', // Text color
  fontSize: '20px', // Default text size for larger screens
};
