import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="text-center">
      <p>
        Website made by <a href="https://siddhant11.netlify.app" target="_blank" rel="noopener noreferrer">Siddhant</a>
      </p>
      <div className="social-icons">
        <a href="https://siddhantvarma.netlify.app" target="_blank" rel="noopener noreferrer" className="coffee-button">
          <i className="fas fa-user"></i>
          <span className="icon-text">About me</span>
        </a>
        <a href="https://instagram.com/xiddhant_11" target="_blank" rel="noopener noreferrer" className="coffee-button">
          <i className="fab fa-instagram"></i>
          <span className="icon-text">Instagram</span>
        </a>
        <a href="https://www.linkedin.com/in/siddhant-varma-54b562234/" target="_blank" rel="noopener noreferrer" className="coffee-button">
          <i className="fab fa-linkedin-in"></i>
          <span className="icon-text">LinkedIn</span>
        </a>
        <a href="https://github.com/sidv11" target="_blank" rel="noopener noreferrer" className="coffee-button">
          <i className="fab fa-github"></i>
          <span className="icon-text">GitHub</span>
        </a>
        <a href="https://wa.me/7441170411" target="_blank" rel="noopener noreferrer" className="coffee-button">
          <i className="fab fa-whatsapp"></i>
          <span className="icon-text">WhatsApp</span>
        </a>
        <a href="https://www.buymeacoffee.com/siddhantvarma" target="_blank" rel="noopener noreferrer" className="coffee-button">
          <i className="fas fa-coffee"></i>
          <span className="icon-text">Buy Me a Coffee</span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
