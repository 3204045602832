// src/components/WhatsAppButton.js
import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const WhatsAppButton = () => {
    return (
        <div id="whatsapp-button">
            <a href="https://wa.me/+918767821636" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp"></i>
            </a>
        </div>
    );
};

export default WhatsAppButton;
