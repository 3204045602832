import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
//import { Gallery } from "./components/gallery";
//import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import Preloader from "././components/preloader"; // Import Preloader component
import '@fortawesome/fontawesome-free/css/all.min.css';
import WhatsAppButton from './components/WhatsappButton';
import Footer from './components/footer'



export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Simulate fetching data
    setTimeout(() => {
      setLandingPageData(JsonData);
      setLoading(false); // Set loading to false after data is fetched
    }, 3000); // Simulate loading delay for 3 seconds
  }, []);

  return (
    <div>
      {loading ? <Preloader /> : null} {/* Conditionally render Preloader */}
      <Navigation />
      <WhatsAppButton />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      {/*<Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />*/}
      <Contact data={landingPageData.Contact} />
      <Team data={landingPageData.Team} />
      <Footer/>
      
    </div>
  );
};

export default App;
