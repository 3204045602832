import React, { useState } from "react";
import emailjs from "emailjs-com";
import wavingDogGif from "./waving-dog.gif";
import '@fortawesome/fontawesome-free/css/all.min.css';

const initialState = {
  petType: "",
  petBreed: "",
  coatColor: "",
  petName: "",
  birthDate: "",
  age: "",
  vaccinationStatus: "",
  dailyDiet: "",
  parentName: "",
  contact: "",
  email: "",
  address: "",
  message: ""
};

export const Contact = (props) => {
  const [formState, setFormState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setFormState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qv00ddm",
        "template_ghj9jm5",
        e.target,
        "CsZ7U4B3WEakORKD2"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get in Touch{" "}
                  <img
                    src={wavingDogGif}
                    alt="Waving Dog"
                    style={{ width: '50px', height: '50px', marginLeft: '10px' }}
                  />
                </h2>
                <p>Get your furry friend booked for grooming!</p>
              </div>
              <form name="contactForm" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="parentName"
                        className="form-control"
                        placeholder="Parent's Name"
                        required
                        value={formState.parentName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="contact"
                        className="form-control"
                        placeholder="Contact"
                        required
                        value={formState.contact}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={formState.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        placeholder="Address"
                        required
                        value={formState.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        name="petType"
                        className="form-control"
                        required
                        value={formState.petType}
                        onChange={handleChange}
                        style={{ height: '50px' }}
                      >
                        <option value="">Select Pet Type</option>
                        <option value="Dog">Dog</option>
                        <option value="Cat">Cat</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="petBreed"
                        className="form-control"
                        placeholder="Pet's Breed"
                        required
                        value={formState.petBreed}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="coatColor"
                        className="form-control"
                        placeholder="Coat Color"
                        required
                        value={formState.coatColor}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="petName"
                        className="form-control"
                        placeholder="Pet's Name"
                        required
                        value={formState.petName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="age"
                        className="form-control"
                        placeholder="Age"
                        required
                        value={formState.age}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <select
                        name="vaccinationStatus"
                        className="form-control"
                        required
                        value={formState.vaccinationStatus}
                        onChange={handleChange}
                        style={{ height: '50px' }}
                      >
                        <option value="">Select Vaccination Status</option>
                        <option value="Fully Vaccinated">Fully Vaccinated</option>
                        <option value="Partially Vaccinated">Partially Vaccinated</option>
                        <option value="Not Vaccinated">Not Vaccinated</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <textarea
                        name="dailyDiet"
                        className="form-control"
                        rows="1"
                        placeholder="Daily Diet"
                        required
                        value={formState.dailyDiet}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    className="form-control"
                    rows="4"
                    placeholder="Any instructions"
                    required
                    value={formState.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Book Now
                  <div className="star-1">★</div>
                  <div className="star-2">★</div>
                  <div className="star-3">★</div>
                  <div className="star-4">★</div>
                  <div className="star-5">★</div>
                  <div className="star-6">★</div>
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? (
                  <a
                  href={`https://maps.app.goo.gl/hcqFDAUwA7RnegQC8?address=${encodeURIComponent(props.data.address)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  {props.data.address}
                </a>
                ) : (
                  "Karve Nagar, Pune"
                )}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? (
                  <a href={`tel:${props.data.phone}`} style={{ color: 'white', textDecoration: 'none' }}>
                    {props.data.phone}
                  </a>
                ) : (
                  ""
                )}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? (
                  <a href={`mailto:${props.data.email}`} style={{ color: 'white', textDecoration: 'none' }}>
                    {props.data.email}
                  </a>
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
          <div className="col-md-12">
  <div className="row">
    <div className="social">
      <ul>
        <li>
          <a href="https://fb.com/61560828140947">
            <i className="fa fa-facebook facebook-icon"></i>
          </a>
        </li>
        <li>
          <a href="https://instagram.com/wiggles_and_walk" >
            <i className="fa fa-instagram instagram-icon"></i>
          </a>
        </li>
        <li>
          <a href="mailto:wigglesandwalk@gmail.com">
            <i className="fa fa-envelope email-icon"></i>
          </a>
        </li>
        <li>
          <a href="https://youtube.com/@wigglesandwalk">
            <i className="fa fa-youtube youtube-icon"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

        </div>
      </div>
    </div>
  );
};