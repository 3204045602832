import React, { useState, useEffect } from "react";
import logo from "./logo.png";

export const Navigation = (props) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
    
      // Close the navbar menu when scrolling
      const navbarMenu = document.querySelector(".navbar-collapse");
      navbarMenu.classList.remove("in");
    
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    
      setPrevScrollPos(currentScrollPos);
    };
    

    const handleBodyClick = (event) => {
      const navbarMenu = document.querySelector(".navbar-collapse");
      if (!navbarMenu.contains(event.target)) {
        navbarMenu.classList.remove("in");
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.body.addEventListener("click", handleBodyClick);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [prevScrollPos, visible]);

  return (
    <nav
      id="menu"
      className={`navbar navbar-default navbar-fixed-top ${
        visible ? "nav-visible" : "nav-hidden"
      }`}
      style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            className="navbar-brand page-scroll"
            href="#page-top"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ height: "30px", marginRight: "10px" }}
            />
            <span>Wiggles & Walk🐾</span>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                Perks
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            
            <li>
              <a href="#contact" className="page-scroll">
                Book Now
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
